
.company-contain {
    height: calc(100vh - 100px);
    background-color: #ffffff;

    .head-box {
        display: flex;
        justify-content: space-between;
        height: 40px;
        padding: 10px 30px 0 20px;

        .title {
            margin-top: 10px;
        }
    }

    .divide {
        padding: 0 20px;
    }

    .filter-box {
        .box1 {
            padding: 0 20px 10px 20px;
            height: 40px;
            display: flex;

            span {
                margin-top: 8px;
                line-height: 32px;
                display: inline-block;
                width: 80px;
                height: 32px;
                text-align: center;
                cursor: pointer;
            }
        }
    }

    .showing {
        display: flex;
        align-items: center;
        /*width: 30px;*/
        height: 30px;
        margin-left: 100px;

        img {
            display: inline-block;
            height: 30px;
            width: 30px;
            margin-right: 10px;
            border-radius: 50%;
        }

        .showing-name {
            max-width: 260px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .student-link {
        cursor: pointer;
    }

    .apply-text {
        color: #00BD1B;
    }

    .disApply-text {
        color: #FF0000;
    }
}
